/*
 * @Descripttion: 
 * @version: 
 * @Author: liwh
 * @Date: 2022-04-15 11:29:01
 * @LastEditors: liwh
 * @LastEditTime: 2022-06-16 09:48:31
 */
// import Vue from 'vue'
// import Vuex from 'vuex'
import {createStore} from 'vuex'  
import {getAvatar,setAvatar,getLogo,setLogo,getCorporateName,setCorporateName} from '../utils/auth'

// Vue.use(Vuex)

export default createStore({
  state: {
    avatar:getAvatar() || "",
    corporateName:getCorporateName() || "",
    logo:getLogo() || '',
    selectedIndexArr: [],//首页被选择的列表索引
    selectedItemArr: [],//首页被选择的列表数据
    dockdevice:"",
    cableLength:0,
    editPolylineLnglat:[] //回显行驶路径上标记用
  },
  mutations: {
    setAvatar(state,value){
      state.avatar = String(value)
      setAvatar(String(value))
    },
    setLogo(state,value){
      state.logo = String(value)
      setLogo(String(value))
    },
    setCorporateName(state,value){
      state.corporateName = String(value)
      setCorporateName(String(value))
    },
    changeSelectedIndexArr(state, item) {
      if (state.selectedIndexArr.includes(item.id)) {
        if (state.selectedIndexArr.length == 1) {
          return
        }
        state.selectedIndexArr = state.selectedIndexArr.filter((el) => {
          return el != item.id
        })
      }
      else {
        state.selectedIndexArr.push(item.id)
      }
      state.selectedIndexArr.sort((a, b) => {
        return a - b;
      });
    },
    changeSelectedItemArr(state, item) {
      if (state.selectedItemArr.some(el => el.id == item.id)) {
        if (state.selectedItemArr.length == 1) {
          return
        }
        state.selectedItemArr = state.selectedItemArr.filter((el) => {
          return el.id != item.id
        })
      }
      else {
        state.selectedItemArr.push(item)
      }
      state.selectedItemArr.sort((a, b) => {
        return a.id - b.id;
      });
    },
    delSelectedIndexArr(state, item) {
      state.selectedIndexArr = state.selectedIndexArr.filter(el => el != item.id)
    },
    delSelectedItemArr(state, item) {
      state.selectedItemArr = state.selectedItemArr.filter(el => el.id != item.id)
    },
    clearSelectIndexArr(state){
      state.selectedIndexArr = []
    },
    clearSelectItemArr(state){
      state.selectedItemArr = []
    },
    setDockDevice(state){
      state.dockdevice = state
    }
  },
  actions: {
    // delSelectedIndexArr(context, item) {
    //   let flag = context.state.selectedIndexArr.some(el => el == item.id)
    //   if (flag) {
    //     context.commit("changeSelectedIndexArr", item)
    //   }
    // },
    // delSelectedItemArr(context, item) {
    //   let flag = context.state.selectedItemArr.some(el => el.id == item.id)
    //   if (flag) {
    //     context.commit("changeSelectedItemArr", item)
    //   }
    // },
  },
  modules: {
  }
})
